import React from 'react';
import { useLocation } from "@reach/router"

import { 
	Layout 
} from 'antd';

import { 
	Header
} from '../components';
import { CartProvider } from '../contexts/cart';
import { getCurrentNavItem } from '../utils';

import '../styles/main.less';

const StandardLayout = ( { children } ) =>  {
	const location = useLocation();
	const currentNavItem = getCurrentNavItem( location );
	return (
		<CartProvider>
			<Layout className={ `page-${ currentNavItem ? currentNavItem.key : 'home' }`}>
				<Header />
				<Layout.Content>
					<div className="site-content">
						{ children }
					</div>
				</Layout.Content>
				<Layout.Footer style={{ textAlign: 'center' }}>Copyright ©{ new Date().getFullYear() } Matt Dehnel</Layout.Footer>
			</Layout>
		</CartProvider>
	);
};

export default StandardLayout;