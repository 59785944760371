const netlify = !!process.env.NETLIFY;
const buildContext = netlify ? process.env.CONTEXT : process.env.GATSBY_BUILD_CONTEXT;
const navItems = [
    {
        key: 'works',
        path: '/works',
        label: 'Works'
    },
    {
        key: 'about',
        path: '/about',
        label: 'About Matt'
    },
    {
        key: 'news',
        path: '/news',
        label: 'News'
    },
    {
        key: 'social-justice',
        path: '/social-justice',
        label: 'Social Justice'
    },
    {
        key: 'contact',
        path: '/contact',
        label: 'Contact'
    }
]

let siteURL = '';
switch( buildContext ) {
    case 'production':
        siteURL = 'https://www.mattdehnel.com/';
        break;
    case 'deploy-preview':
    case 'branch-deploy':
        // siteURL = 'https://mattdehnel-dot-com.netlify.app/';
        siteURL = `${ process.env.DEPLOY_PRIME_URL }/`;
        break;
    case 'development':
        // ngrok required for local cart/checkout testing
        siteURL = 'https://b7b58cb09339.ngrok.io/';
        // siteURL = 'http://localhost:8000/';
        break;
    default:
        throw new Error( `No GATSBY_BUILD_CONTEXT environment variable found for buildContext ${ buildContext }` )
}

const snipcartMode = buildContext === 'production' ? 'LIVE' : 'TEST';

export { 
    navItems,
    siteURL,
    snipcartMode,
    buildContext,
    netlify
}