import { navItems } from '../config';

const getCurrentNavItem = ( { hostname, pathname } ) => {

	pathname = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;

	switch( hostname ) {
	case 'matt-dehnel-publishing.gitlab.io':
		return navItems.find( item => item.path === pathname.replace( 'sales-website/', '' ) );
	default:
		return navItems.find( item => item.path === pathname );
	}

};

export { getCurrentNavItem };