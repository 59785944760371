import React from 'react';
import { Link } from 'gatsby'
import { Layout, Typography } from 'antd';

import Nav from './Nav';
import HeaderCartIcon from './HeaderCartIcon';
import MDIcon from "../../images/md-icon-with-initial-fills.svg"

const Header = () => {

	return (
		<Layout.Header>
			<Link to="/" className="home-link">
				<MDIcon />
				<Typography.Title level={ 1 }>Matt Dehnel Music</Typography.Title>
			</Link>
			<Nav />
			<HeaderCartIcon />
		</Layout.Header>
	);
};

export { Header };