import React, { useContext } from 'react';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, Button } from 'antd';

import CartContext from '../../contexts/cart';

const HeaderCartIcon = () => {

	const cartState = useContext( CartContext );

    return (
        <div className="header-cart-icon">
            {
                cartState &&
                cartState.cart &&
                cartState.cart.items.count > 0 &&
                <Badge count={ cartState.cart.items.count } size="small">
                    <Button type="link" className="snipcart-checkout"><ShoppingCartOutlined /></Button>
                </Badge>
                
            }    
        </div>
    )

}

export default HeaderCartIcon;