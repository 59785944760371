import React from 'react';
import { Link } from 'gatsby'
import { Menu } from 'antd';
import { useLocation } from "@reach/router"

import { navItems } from '../../config';
import { getCurrentNavItem } from '../../utils';

const Nav = () => {
	const location = useLocation();
	const currentNavItem = getCurrentNavItem( location );
	const selectedKeys = [];
	if( currentNavItem ) {
		selectedKeys.push( currentNavItem.key );
	}
	return (
		<Menu mode="horizontal" defaultSelectedKeys={ selectedKeys } selectedKeys={ selectedKeys }>
			{
				navItems.map( item => <Menu.Item key={ item.key }><Link to={ item.path }>{ item.label }</Link></Menu.Item> )
			}
		</Menu>
	);
};

export default Nav;