import React, { useState, useEffect } from 'react';
import { notification } from 'antd';

import { netlify, siteURL } from '../config';

const CartContext = React.createContext( { items: [] } );

const openNotification = ( type, message, description = '' ) => {
    notification[type]({
        message,
        description
    });
};

const captureError = async error => {
    if ( netlify ) {
        await fetch( `${ siteURL }.netlify/functions/snipcart-error`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( error )
        } );
    } else {
        console.error( `Captured error from snipcart, in a non-netlify context so just logging here:\n${ JSON.stringify( error, null, 4 ) }` );
    }
}

const CartProvider = ( { children } ) => {

    const [ cartState, setCartState ] = useState({});
    const listenerUnsubscribers = [];

    const snipcartCartWatcher = async () => {
        const cartUpdateUnsubscriber = window.Snipcart.store.subscribe(() => {
            console.log( 'CART UPDATE' );
            setCartState(window.Snipcart.store.getState())
        })
        listenerUnsubscribers.push( cartUpdateUnsubscriber );
        const itemAddedUnsubscriber = window.Snipcart.events.on('item.added', async (cartItem) => {
            console.log( `Item Added:\n${ JSON.stringify( cartItem, null, 4 ) }` );
            openNotification( 'success', `Added: ${ cartItem.name }` )
        });
        listenerUnsubscribers.push( itemAddedUnsubscriber );
        const itemUpdatedUnsubscriber = window.Snipcart.events.on('item.updated', (cartItem) => {
            console.log( `Item Updated:\n${ JSON.stringify( cartItem, null, 4 ) }` );
            openNotification( 'success', `Updated: ${ cartItem.name }` )
        });
        listenerUnsubscribers.push( itemUpdatedUnsubscriber );
        const cartConfirmedUnsubscriber = window.Snipcart.events.on('cart.confirmed', (cartConfirmResponse) => {
            console.log(cartConfirmResponse);
        });
        listenerUnsubscribers.push( cartConfirmedUnsubscriber );
        const cartConfirmErrorUnsubscriber = window.Snipcart.events.on('cart.confirm.error', async (confirmError) => {
            console.log(confirmError);
            await captureError( { ...confirmError, cartEmail: cartState.cart.email } );
            let message = 'Sorry, something went wrong with processing your order.  We have captured details about the error and will check into what went wrong. '
            if( cartState.cart.email ) {
                message += `We have your email address as ${ cartState.cart.email } and will contact you about your order as appropriate.`;
            } else {
                message += `We do not have an email address associated with the order yet so you can try again later to see if we've fixed the problem or you can use the Contact Matt tab to reach out about the error.`
            }
            openNotification( 'error', `Order Processing Error`, message  )
        });
        listenerUnsubscribers.push( cartConfirmErrorUnsubscriber );
    }

    useEffect(() => {

        if( !window.Snipcart ) {
            document.addEventListener('snipcart.ready', snipcartCartWatcher);
            console.log( 'MDC: snipcart.ready listener added' )
        } else {
            console.log( 'MDC: snipcart already ready' )
            snipcartCartWatcher();
        }

        return () => {
            // clean up the event handler when the component unmounts
            document.removeEventListener('snipcart.ready', snipcartCartWatcher);
            // unsubscribe to our listeners
            listenerUnsubscribers.forEach( u => u() );
        };

    });

    return (
        <CartContext.Provider
            value={ cartState }
        >
            { children }
        </CartContext.Provider>
    )
}

export default CartContext;
export { CartProvider }